.react-flow__handle {
  opacity: 1;
  background-color: #eaeaea;
}

/* .react-flow__node:has(.react-flow__handle) {
  &:hover {
    .react-flow__handle {
      opacity: 1;
    }
  }
}
 */


.custom-source-handle:hover {
  border-color: $secondary-purple-20 !important;
  width: 1rem !important;
  height: 1rem !important;
}