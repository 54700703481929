@import 'src/styles';

// Buttons
.button-common {
  font-family: $font-semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  border: 0px;
  line-height: 14.4px;
  letter-spacing: -0.03em;
  border-radius: 8px;
  width: 100%;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;

  img.margin-right {
    margin-right: 10px;
  }
  i.fa {
    margin-right: 10px;
  }
}

.button-without-border {
  padding: 16px;

  &.no-icon {
    padding: 17px 16px;
  }
}

.button-with-border {
  padding: 15px;

  &.no-icon {
    padding: 16px 15px;
  }
}

.btn-tiny {
  font-size: 10px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  border-radius: 8px;

  &.button-without-border {
    padding: 5.5px !important;
  }

  &.button-with-border {
    padding: 4.5px !important;
  }
}
.btn-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  border-radius: 8px;

  &.button-without-border {
    padding: 8px !important;
  }

  &.button-with-border {
    padding: 7px !important;
  }
}
.btn-medium {
  font-size: 12px !important;
  line-height: 120% !important;
  letter-spacing: -0.03em !important;
  border-radius: 8px;

  &.button-without-border {
    padding: 10px !important;
    
    &.no-icon {
      padding: 11px 10px !important;
    }
  }

  &.button-with-border {
    padding: 9px !important;

    &.no-icon {
      padding: 9.5px 9px !important;
    }
  }
}

.primary-button[aria-disabled="true"],
.cancel-button[aria-disabled="true"],
.pending-button[aria-disabled="true"],
.completed-button[aria-disabled="true"],
.disabled-button[aria-disabled="true"],
.blocked-button[aria-disabled="true"],
.tertiary-button[aria-disabled="true"],
.link-button[aria-disabled="true"],
.link-dark-button[aria-disabled="true"],
.warning-button[aria-disabled="true"] {
  @extend .button-common, .button-without-border;
  background: $gray-color2;
  color: $disabled-color;
  cursor: default;
}

.secondary-button[aria-disabled="true"] {
  @extend .button-common, .button-with-border;
  background: $neutral-white;
  border: 1px solid $neutral-gray-warm-40;
  color: $neutral-gray-warm-20;
  cursor: default;
}


.primary-button[aria-disabled="false"] {
  @extend .button-common, .button-without-border;
  background: linear-gradient(
    to right,
    $primary-button-bg-color,
    $primary-button2-bg-color
  ) !important;
  color: $primary-button-text-color !important;
}

.primary-button[aria-disabled="false"]:hover {
  background: linear-gradient(
    to right,
    $primary-button-hover-bg-color,
    $primary-button-hover2-bg-color
  ) !important;
}

.secondary-button[aria-disabled="false"]:hover {
  border: 1px solid $secondary-purple-20;
}

.cancel-button[aria-disabled="false"],
.secondary-button[aria-disabled="false"] {
  @extend .button-common, .button-with-border;
  background: $secondary-button-bg-color;
  border: 1px solid $secondary-button-border-color;
  color: $secondary-button-text-color;
}

.pending-button[aria-disabled="false"] {
  @extend .button-common, .button-without-border;
  background: $secondary-color;
  color: $white;
}

.completed-button[aria-disabled="false"] {
  @extend .button-common, .button-without-border;
  background: $primary-light-color;
  color: $white;
}

.btn-height-small {
  max-height: 33px;
}

.blocked-button[aria-disabled="false"],
.tertiary-button[aria-disabled="false"] {
  @extend .button-common, .button-with-border;
  background: $tag-bg-color !important;
  color: $on-tag-text-color !important;
  border: 1px solid $on-tag-text-color;
  width: auto;
}

.blocked-button[aria-disabled="false"]:hover,
.tertiary-button[aria-disabled="false"]:hover {
  background: $secondary-light-color !important;
}

.link-button[aria-disabled="false"] {
  @extend .highlight-text-color, .pointer;
  width: fit-content;
  position: relative;
  border: none;
  padding: 0;
  text-decoration: underline;
  text-decoration-color: $link-underline-color;
}

.link-dark-button[aria-disabled="false"] {
  @extend .font-body-b2-sb, .underline;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: $on-light-text-color;
  border: none;
  padding: 0;
  background-color: transparent;
}

.warning-button[aria-disabled="false"] {
  @extend .button-common, .button-with-border;
  background: $white;
  color: $strong-error-color;
  border: 1px solid $strong-error-color;
}
